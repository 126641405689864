<template>
  <div class="widget widget-sales-category" v-if="customers && customers.length > 0">
    <div class="widget-heading">
      <h5>Customers by Category</h5>
    </div>
    <div class="widget-content">
      <!-- <apexchart v-if="sales_donut_options" height="460" type="donut" :options="sales_donut_options" :series="sales_donut_series"></apexchart> -->
      <apexchart v-if="customerChartData.length > 0" height="600" type="donut" :options="customer_donut_options"
        :series="computedSeries"></apexchart>
    </div>
  </div>
  <div class="widget text-white" v-else>
    <p class="sub-title text-center p-4">No Customers</p>
  </div>
</template>


<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
import '@/assets/sass/widgets/widgets.scss';

export default {
  data() {
    return {
    };
  },
  async mounted() {
    await this.fetchCustomers();
  },
  destroyed() { },
  watch: {},
  computed: {
    customers() {
      return this.$store.state.customers.customers_view;
    },
    customerChartData() {
      // Initialize an object to hold the count of each type
      const counts = {};

      // Loop through the customers array to populate the counts object
      this.customers.forEach((customer) => {
        if (!counts[customer.customer_type]) {
          counts[customer.customer_type] = 1; // Initialize this type with a count of 1
        } else {
          counts[customer.customer_type]++; // Increment the count for this type
        }
      });

      // Transform the counts object into an array of objects with type and total_count
      const result = Object.keys(counts).map((customer_type) => ({
        type: customer_type,
        total_count: counts[customer_type],
      }));

      return result;
    },
    computedSeries() {
      // Compute series data dynamically from `chartData`
      return this.customerChartData.map((item) => item.total_count);
    },
    //Sales by Category
    customer_donut_options() {
      // Access dark mode flag from store
      const is_dark = this.$store.state.is_dark_mode;

      // Extract labels and series data from `customerChartData`
      const labels = this.customerChartData.map((item) => item.type);
      const series = this.customerChartData.map((item) => item.total_count);

      // Define base colors for light and dark mode
      const baseColorsLight = ['#e2a03f', '#5c1ac3', '#e7515a'];
      const baseColorsDark = ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'];

      // Function to generate a random color
      const generateRandomColor = () => {
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        return randomColor;
      };

      // Ensure there are enough colors for each label
      const colors =
        labels.length > baseColorsLight.length ? [...(is_dark ? baseColorsDark : baseColorsLight)] : is_dark ? baseColorsDark.slice(0, labels.length) : baseColorsLight.slice(0, labels.length);

      // If not enough predefined colors, fill in with random colors
      while (colors.length < labels.length) {
        colors.push(generateRandomColor());
      }

      // Option object for the chart
      let option = {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const selectedIndex = config.dataPointIndex;
              const selectedData = this.customerChartData[selectedIndex];
              console.log('selectedData-',selectedData)
              if (selectedData && selectedData.type) {
                this.$router.push({
                  name: 'customers',
                  query: { customer_type: selectedData.type },
                });
              }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        expandOnClick: is_dark ? false : true,
        stroke: {
          show: true,
          width: 15,
          colors: is_dark ? '#0e1726' : '#fff',
        },
        colors: colors,
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '14px',
          markers: {
            width: 10,
            height: 10,
          },
          height: 100,
          offsetY: 15, // Reduce offset if necessary
          itemMargin: {
            horizontal: 5, // Reduced margin
            vertical: 0,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '60%',
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '24px',
                  fontFamily: 'Nunito, sans-serif',
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '26px',
                  fontFamily: 'Nunito, sans-serif',
                  color: is_dark ? '#bfc9d4' : undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#888ea8',
                  fontSize: '24px',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  },
                },
              },
            },
          },
        },
        labels: labels,
      };

      if (is_dark) {
        option['states'] = {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        };
      }

      return option;
    },
  },
  methods: {
    async fetchCustomers() {
      this.asyncLoading = true;
      await this.$store.dispatch('customers/getAllCustomerView');
      this.asyncLoading = false;
    },
  },
};
</script>
