import axios from 'axios';
import { encryptData, decryptData } from '../../../utils/encryption';

const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL :  process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_MISC_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_MISC_URL :  process.env.VUE_APP_LOCAL_MISC_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL :  process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL


export default {
  // MANPOWER TYPE
    async getManPowerTypes({ commit, state }) {
        try {
          const response = await axios.get(`${API_SERVICE_URL}/business/manpower_types`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const manpower_types = response.data.manpower_types;
          if(manpower_types){
            commit('setManpowerTypes', manpower_types);
          }else{
            throw new Error('Cannot Retreive Manpower Types'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    async addManPowerType({dispatch, commit}, manpower_type){
        try {
          const response = await axios.post(`${API_SERVICE_URL}/business/manpower_types`, manpower_type,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          if(response.data.status){
            commit('setAddedManPowerType', response.data.manpower_type);
            await dispatch('getManPowerTypes');
          }else{
            throw new Error('Cannot Add Manpower Type');
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
        }
    },
    
    async updateManpowerType({dispatch, commit}, manpower_type){
      try {
        const response = await axios.put(`${API_SERVICE_URL}/business/manpower_types/${manpower_type.id}`, manpower_type,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if(response.data.status){
          commit('setAddedManPowerType', response.data.manpower_type);
          await dispatch('getManPowerTypes');
        }else{
          throw new Error('Cannot Update Manpower Type');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
    },
    async deleteManpowerType({ commit, dispatch }, id) {
      try {
        const response = await axios.delete(`${API_SERVICE_URL}/business/manpower_types/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data.status) {
          return response.data.status
        } else {
          throw new Error('Cannot Get Manpower Type ');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';
  
        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }
  
        throw new Error(errorMessage);
      }
    },
    // RATE CARD
    async getRateCards({ commit }) {
        try {
          const response = await axios.get(`${API_SERVICE_URL}/business/rate_cards`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const rate_cards = response.data.rate_cards;
          if(rate_cards){
            commit('setRateCards', rate_cards);
          }else{
            throw new Error('Cannot Retreive Rate Cards'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    async addRateCard({dispatch, commit}, rate_card){
      try {
        const response = await axios.post(`${API_SERVICE_URL}/business/rate_cards`, rate_card,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if(response.data.status){
          commit('setAddedRateCard', response.data.rate_card);
          await dispatch('getRateCards');
        }else{
          throw new Error('Cannot Add Rate Card');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
    },
    async updateRateCard({dispatch, commit}, rate_card){
      try {
        const response = await axios.put(`${API_SERVICE_URL}/business/rate_cards/${rate_card.id}`, rate_card,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if(response.data.status){
          commit('setAddedRateCard', response.data.rate_card);
          await dispatch('getRateCards');
        }else{
          throw new Error('Cannot Add Rate Card');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
    },
    async deleteRateCard({ commit, dispatch }, id) {
      try {
        const response = await axios.delete(`${API_SERVICE_URL}/business/rate_cards/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data.status) {
          return response.data.status
        } else {
          throw new Error('Cannot Get Rate Card ');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';
  
        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }
  
        throw new Error(errorMessage);
      }
    },
    //USER
    async addUser({ commit }, userData) {
      if(userData.phoneNumber) userData.phoneNumber = userData.phoneNumber.replace(/\D/g, '');
      
      if(userData.email) userData.email = userData.email.toLowerCase().replace(/\s/g, '');

        try {
          const response = await axios.post(`${API_BASE_URL}/api/users/add`, userData,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const user = response.data.user;
          if(user){
            commit('setAddedUser', user);
          }else{
            throw new Error('Cannot Add User'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    
    async updateUser({ commit }, userData) {
      if(userData.id){
        if(userData.phoneNumber) userData.phoneNumber = userData.phoneNumber.replace(/\D/g, '');
      
      if(userData.email) userData.email = userData.email.toLowerCase().replace(/\s/g, '');

        try {
          const response = await axios.put(`${API_BASE_URL}/api/users/${userData.id}`, userData,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const user = response.data.user;
          if(user){
            commit('setAddedUser', user);
          }else{
            throw new Error('Cannot Add User'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
      }else{
        throw new Error('User Id Is Required');
      }
      
    },

    async checkUser({ commit }, {email, phoneNumber}) {
      if(phoneNumber){
        phoneNumber = phoneNumber.replace(/\D/g, '');
      }
      if(email){
        email = email.toLowerCase().replace(/\s/g, '');
      }
      
        try {
          const response = await axios.post(`${API_BASE_URL}/api/checkuser`, {email, phoneNumber}, {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            }
           });
          const user = response.data.user;
          if(user){
            commit('setAddedUser', user);
          }else{
            commit('setAddedUser', null)
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    //Contracts
     async getContracts({ commit }) {
      try {
          const response = await axios.get(`${API_SERVICE_URL}/business/contracts`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
          const contracts = response.data.contracts;
          if(contracts){
            commit('setContracts', contracts);
          }else{
            throw new Error('Cannot Retreive Contracts'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    async addContract({ commit }, contractData) {

        try {
          const response = await axios.post(`${API_SERVICE_URL}/business/contracts`, contractData,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const contract = response.data.contract;
          if(contract){
            commit('setAddedContract', contract);
          }else{
            throw new Error('Cannot Add Contract'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    
    async updateContract({ commit }, contractData) {
      if(contractData.id){
        
        try {
          const response = await axios.put(`${API_SERVICE_URL}/business/contracts/${contractData.id}`, contractData,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const contract = response.data.contract;
          if(contract){
            commit('setAddedContract', contract);
          }else{
            throw new Error('Cannot Add contract'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
      }else{
        throw new Error('User Id Is Required');
      }
      
    },

    async getContractsView({ commit }, { page = null, pageSize = null } = {}) {
      try {
        const params = {};
        if (page !== null && pageSize !== null) {
          params.page = page;
          params.pageSize = pageSize;
        }
        const response = await axios.get(`${API_SERVICE_URL}/business/contracts_view`, { 
          params,
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
         });
          const contracts_view = response.data.contracts_view;
          if(contracts_view){
            return contracts_view
          }else{
            throw new Error('Cannot Retreive Contracts View'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },

    // contract details
    async getContractDetails({ commit }) {
      try {
          const response = await axios.get(`${API_SERVICE_URL}/business/contract_details`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
          const contract_details = response.data.contract_details;
          if(contract_details){
            commit('setContractDetails', contract_details);
            return contract_details
          }else{
            throw new Error('Cannot Retreive Contract Details'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    async getContractDetailView({ commit }, id) {
      try {
          const response = await axios.get(`${API_SERVICE_URL}/business/contract_details_view/${id}`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
          const contract_detail = response.data.contract_detail;
          if(contract_detail){
            return contract_detail
          }else{
            throw new Error('Cannot Retreive Contract Details View'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    async addContractDetail({ commit }, contractDetailData) {

      try {
        const response = await axios.post(`${API_SERVICE_URL}/business/contract_details`, contractDetailData,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
         });
        const contract_detail = response.data.contract_detail;
        if(contract_detail){
          commit('setAddedContractDetail', contract_detail);
        }else{
          throw new Error('Cannot Add Contract Detail'); // Custom error message for API errors
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
  },
  
  async getContractDetail({ commit }, id) {
    if(id){
      
      try {
        const response = await axios.get(`${API_SERVICE_URL}/business/contract_details/${id}`,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
         });
        const contract_detail = response.data.contract_detail;
        if(contract_detail){
          commit('setContractDetail', contract_detail);
          return contract_detail
        }else{
          throw new Error('Cannot Get contract detail'); // Custom error message for API errors
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
    }else{
      throw new Error('Contract Detail Id Is Required');
    }
    
  },
  async updateContractDetail({ commit }, contractDetailData) {
    if(contractDetailData.id){
      
      try {
        const response = await axios.put(`${API_SERVICE_URL}/business/contract_details/${contractDetailData.id}`, contractDetailData,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
         });
        const contract_detail = response.data.contract_detail;
        if(contract_detail){
          commit('setAddedContractDetail', contract_detail);
        }else{
          throw new Error('Cannot Add contract detail'); // Custom error message for API errors
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
    }else{
      throw new Error('User Id Is Required');
    }
    
  },

  //REmove Contract Detail By ID
  async deleteContractDetail({ commit, dispatch }, id) {
    try {
      const response = await axios.delete(`${API_SERVICE_URL}/business/contract_details/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.status
      } else {
        throw new Error('Cannot Get Contract Detail');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },


    //Shifts
    async getShifts({ commit }) {
      try {
          const response = await axios.get(`${API_SERVICE_URL}/business/shifts`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
          const shifts = response.data.shifts;
          if(shifts){
            commit('setShifts', shifts);
          }else{
            throw new Error('Cannot Retreive Shifts'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    async addShift({ commit }, shiftData) {
      console.log(shiftData);
        try {
          const response = await axios.post(`${API_SERVICE_URL}/business/shifts`, shiftData,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const shift = response.data.shift;
          if(shift){
            commit('setAddedShift', shift);
          }else{
            throw new Error('Cannot Add Shift'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },
    
    async updateShift({ commit }, shiftData) {
      if(shiftData.id){
        
        try {
          const response = await axios.put(`${API_SERVICE_URL}/business/shifts/${shiftData.id}`, shiftData,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const shift = response.data.shift;
          if(shift){
            commit('setAddedShift', shift);
          }else{
            throw new Error('Cannot Add shift'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
      }else{
        throw new Error('User Id Is Required');
      }
      
    },

    async deleteShift({ commit, dispatch }, id) {
      try {
        const response = await axios.delete(`${API_SERVICE_URL}/business/shifts/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data.status) {
          return response.data.status
        } else {
          throw new Error('Cannot Get Shift ');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';
  
        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }
  
        throw new Error(errorMessage);
      }
    },

    //Transaction
    async addTransaction({dispatch, commit}, transaction){
      try {
        const response = await axios.post(`${API_SERVICE_URL}/business/transactions`, transaction,{ 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if(response.data.status){
          commit('setAddedTransaction', response.data.transaction);
          await dispatch('getTransactions');
        }else{
          throw new Error('Cannot Add Transaction');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                // If there are multiple validation errors, concatenate their messages
                errorMessage = error.response.data.errors.map(err => err.message).join(", ");
            } else {
                // Use the main error message if available
                errorMessage = error.response.data.message || errorMessage;
            }
        } else if (error instanceof Error) {
            // Handle generic Error instances
            errorMessage = error.message;
        } else {
            // Handle cases where error is not an instance of Error
            errorMessage = `Error - ${error.toString()}`;
        }
    
        throw new Error(errorMessage);
        
      }
    },
    async updateTransaction({ commit }, transaction) {
      const transactionData = {...transaction};
      if(transactionData.id){
        try {
          const response = await axios.put(`${API_SERVICE_URL}/business/transactions/${transactionData.id}`, transactionData, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const transaction = response.data.transaction;
          if(transaction){
            commit('setAddedTransaction', transaction);
          }else{
            throw new Error('Cannot Add transaction'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
      }else{
        throw new Error('User Id Is Required');
      }
      
    },
    async getTransaction({ commit }, id) {
      if(id){
        
        try {
          const response = await axios.get(`${API_SERVICE_URL}/business/transactions/${id}`,{ 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
           });
          const transaction = response.data.transaction;
          if(transaction){
            commit('setTransaction', transaction);
          }else{
            throw new Error('Cannot Get Transaction By Id'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
      }else{
        throw new Error('Contract Detail Id Is Required');
      }
      
    },
    async getTransactions({ commit }) {
      try {
          const response = await axios.get(`${API_SERVICE_URL}/business/transactions`, { 
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
          const transactions = response.data.transactions;
          if(transactions){
            commit('setTransactions', transactions);
          }else{
            throw new Error('Cannot Retreive Transactions'); // Custom error message for API errors
          }
        } catch (error) {
          let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
          
        }
    },

    
};
