<template>
  <div class="widget widget-sales-category" v-if="employees && employees.length > 0">
    <div class="widget-heading">
      <h5>Employees by Category</h5>
    </div>
    <div class="widget-content">
      <!-- Only show chart if we have valid data -->
      <apexchart
        v-if="employeeChartData.length > 0 && computedSeries.length > 0"
        height="600"
        type="donut"
        :options="employee_donut_options"
        :series="computedSeries"
      ></apexchart>
    </div>
  </div>
  <div class="widget text-white" v-else>
    <p class="sub-title text-center p-4">No Employees</p>
  </div>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
import '@/assets/sass/widgets/widgets.scss';

export default {
  data() {
    return {
      asyncLoading: false,
    };
  },
  async mounted() {
    await this.fetchEmployees();
  },
  destroyed() {},
  computed: {
    employees() {
      return this.$store.state.employees.employees;
    },
    employeeChartData() {
      if (!this.employees || !Array.isArray(this.employees)) {
        return [];
      }
      const counts = {};
      const designationInfo = {};

      this.employees.forEach((employee) => {
        if (employee && employee.ManpowerType && employee.ManpowerType.name) {
          const { id, name } = employee.ManpowerType;
          if (!counts[name]) {
            counts[name] = 1;
            designationInfo[name] = id;
          } else {
            counts[name]++;
          }
        }
      });

      return Object.keys(counts).map((employee_type) => ({
        id: designationInfo[employee_type],
        type: employee_type,
        total_count: Number(counts[employee_type]), // ensure it's a number
      }));
    },
    computedSeries() {
      if (!Array.isArray(this.employeeChartData)) return [];
      return this.employeeChartData.map((item) => Number(item.total_count) || 0);
    },
    employee_donut_options() {
      const is_dark = this.$store.state.is_dark_mode;
      const labels = (this.employeeChartData || []).map((item) => item.type);

      const baseColorsLight = ['#e2a03f', '#5c1ac3', '#e7515a'];
      const baseColorsDark = ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'];

      // Generate a fully valid 6-digit hex color
      const generateRandomColor = () => {
        let colorInt = Math.floor(Math.random() * 16777215);
        let hex = colorInt.toString(16);
        while (hex.length < 6) {
          hex = '0' + hex;
        }
        return '#' + hex;
      };

      let colors =
        labels.length > baseColorsLight.length
          ? [...(is_dark ? baseColorsDark : baseColorsLight)]
          : is_dark
          ? baseColorsDark.slice(0, labels.length)
          : baseColorsLight.slice(0, labels.length);

      while (colors.length < labels.length) {
        colors.push(generateRandomColor());
      }

      // Always provide stroke.colors as an array
      const strokeColor = is_dark ? '#0e1726' : '#fff';

      return {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const selectedIndex = config.dataPointIndex;
              const selectedData = this.employeeChartData[selectedIndex];
              if (selectedData && selectedData.id) {
                this.$router.push({
                  name: 'employees',
                  query: { designationId: selectedData.id },
                });
              }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        expandOnClick: !is_dark,
        stroke: {
          show: true,
          width: 5,
          colors: [strokeColor],
        },
        colors: colors,
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '14px',
          markers: {
            width: 10,
            height: 10,
          },
          height: 100,
          offsetY: 15,
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '24px',
                  fontFamily: 'Nunito, sans-serif',
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '26px',
                  fontFamily: 'Nunito, sans-serif',
                  color: is_dark ? '#bfc9d4' : undefined,
                  offsetY: 16,
                  formatter: (val) => val,
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#888ea8',
                  fontSize: '24px',
                  formatter: (w) =>
                    w.globals.seriesTotals.reduce((a, b) => a + b, 0),
                },
              },
            },
          },
        },
        labels: labels,
        // Remove states if it causes issues, or ensure correct format
        states: is_dark
          ? {
              hover: { filter: { type: 'none', value: 0 } },
              active: { filter: { type: 'none', value: 0 } },
            }
          : {},
      };
    },
  },
  methods: {
    async fetchEmployees() {
      this.asyncLoading = true;
      await this.$store.dispatch('employees/getEmployees');
      this.asyncLoading = false;
    },
  },
};
</script>
