import axios from 'axios';
import { encryptData, decryptData } from '../../../utils/encryption';

const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL :  process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_MISC_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_MISC_URL :  process.env.VUE_APP_LOCAL_MISC_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL :  process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL


export default {
  //Customer
  async getCustomers({ commit }, { page = null, pageSize = null } = {}) {
    try {
      const params = {};
      if (page !== null && pageSize !== null) {
        params.page = page;
        params.pageSize = pageSize;
      }
      const response = await axios.get(`${API_SERVICE_URL}/customers`, { 
        params,
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
       });
      const customers = response.data.customers;
      if(customers){
        commit('setCustomers', customers);
      }else{
        throw new Error('Cannot Retreive Customers'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
      
    }
},
async getAllCustomerView({ commit }) {
  try {
    const response = await axios.get(`${API_SERVICE_URL}/customers/customer_view`,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      commit('setCustomersView', response.data.customers);
    }else{
      throw new Error('Cannot Get Customers');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},

async searchCustomersByQuery({ commit }, { query, customer_types, page = 1, pageSize = 10 }) {
  try {
        const response = await axios.get(`${API_SERVICE_URL}/customers/getByQuery`, {
          params: {
            query,                // The search query (for name/contact/email)
            customer_types, // comma-separated string of IDs
            page,
            pageSize
          },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        });
        commit('setCustomers', response.data.customers);
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
},

async addCustomer({dispatch, commit}, customer){
  try {
    const response = await axios.post(`${API_SERVICE_URL}/customers`, customer,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      commit('setAddedCustomer', response.data.customer);
      await dispatch('getCustomers');
    }else{
      throw new Error('Cannot Add customer');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},
async updateCustomer({dispatch, commit}, customer){
  
  try {
    const response = await axios.put(`${API_SERVICE_URL}/customers/${customer.uuid}`, customer,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      commit('setAddedCustomer', response.data.customer);
      await dispatch('getCustomers');
    }else{
      throw new Error('Cannot Update Customer');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},
async getCustomer({commit}, user_id){
  console.log('GET customer BY ID - ', user_id)
  try {
    const response = await axios.get(`${API_SERVICE_URL}/customers/userId/${user_id}`,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      commit('setAddedCustomer', response.data.customer);
    }else{
      throw new Error('Cannot Get Customer');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},
async getCustomerById({commit}, uuid){
  try {
    const response = await axios.get(`${API_SERVICE_URL}/customers/${uuid}`,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      // commit('setCustomer', response.data.customer);
      // commit('setAddedCustomer', response.data.customer);
      return response.data.customer;
    }else{
      throw new Error('Cannot Get Customer');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
  }
},

async getTodaysAssignmentByUUID({commit}, uuid){
  try {
    const response = await axios.get(`${API_SERVICE_URL}/customers/todays_assignment/${uuid}`,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      return response.data.assignment_today;
    }else{
      throw new Error('Cannot Get Customer Assignment For Today');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
  }
},
async getCustomerPublic({commit}, uuid){
  try {
    const response = await axios.get(`${API_SERVICE_URL}/customers/uuid/${uuid}`,{ 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      commit('setCustomer', response.data.customer);
    }else{
      throw new Error('Cannot Get Customer');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},
//EMP Document
async addCustomerDocument({dispatch, commit}, customerDocument){
  if(customerDocument['customerId'] != null || customerDocument['customerId'] != undefined || customerDocument['customerId'] != ''){
    try {
      const response = await axios.post(`${API_SERVICE_URL}/customers/documents`, customerDocument,{ 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
        }
      });
      if(response.data.status){
        // console.log(data- customer_document)
        commit('setAddedDocument', response.data.customer_document);
        await dispatch('getCustomers');
      }else{
        throw new Error('Cannot ADD Customer Document');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  }else{
    throw new Error(`Error - Customer Id Not Found`);
  }
  
},
async updateCustomerDocument({dispatch, commit}, customerDocument){
  if(customerDocument['customerId'] != null || customerDocument['customerId'] != undefined || customerDocument['customerId'] != ''){
    let foundIdValue = null;
    for (const [key, value] of customerDocument.entries()) {
        if (key === 'id') {
        foundIdValue = value;
        break; // Stop the loop once the 'id' value is found
        }
    }
    try {
      const response = await axios.put(`${API_SERVICE_URL}/customers/documents/${foundIdValue}`, customerDocument,{ 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
        }
      });
      if(response.data.status){
        commit('setAddedDocument', response.data.customer_document);
        await dispatch('getCustomers');
      }else{
        throw new Error('Cannot Update Customer Document');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
    }
  }
},

async deleteCustomerDocument({dispatch, commit}, id){
  
  try {
    const response = await axios.delete(`${API_SERVICE_URL}/customers/documents/${id}`, { 
      headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if(response.data.status){
      console.log('DELETED')
    }else{
      throw new Error('Cannot Delete Customer Document');
    }
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},
async addCustomerDocuments({dispatch, commit}, customerDocuments){
  
  try {
    let addedDocuments = []
    for (const document of customerDocuments) {
      const formData = new FormData();
      // Assuming 'document' is a file object or similar
      
      for (let key in document) {
        if (key !== 'imageUrl' && key !== 'selected_file' && document[key] != null) {
            formData.append(key, document[key]);
        }
      }
      // Append 'selected_file' separately if needed
      if (document.selected_file) {
          formData.append('file', document.selected_file);
      }
      if(document.customerId){
        const response = await axios.post(`${API_SERVICE_URL}/customers/documents`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          }
        });
  
        if (response.data.status) {
          // commit('setAddedDocument', response.data.customer_document);
          addedDocuments.push(response.data.customer_document)
          console.log(' Doc Added- ', response.data.customer_document)
        } else {
          throw new Error('Cannot ADD Customer Document');
        }
      }
      

    }
    commit('setAddedDocuments', addedDocuments);
    await dispatch('getCustomers');
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},

async updateCustomerDocuments({dispatch, commit}, customerDocuments){
  
  try {
    let addedDocuments = []
    for (const document of customerDocuments) {
      const formData = new FormData();
      // Assuming 'document' is a file object or similar
      
      for (let key in document) {
        if (key !== 'imageUrl' && key !== 'selected_file' && document[key] != null) {
            formData.append(key, document[key]);
        }
      }
      // Append 'selected_file' separately if needed
      if (document.selected_file) {
          formData.append('file', document.selected_file);
      }
      if(document.customerId){
        const response = await axios.put(`${API_SERVICE_URL}/customers/documents/${document['id']}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          }
        });
  
        if (response.data.status) {
          // commit('setAddedDocument', response.data.customer_document);
          addedDocuments.push(response.data.customer_document)
          console.log(' Doc Updated- ', response.data.customer_document)
        } else {
          throw new Error('Cannot Update Customer Document');
        }
      }
      
    }
    commit('setAddedDocuments', addedDocuments);
    await dispatch('getCustomers');
  } catch (error) {
    let errorMessage = 'Unknown error occurred';

    // Check if the error is a response from the server with structured data
    if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
        }
    } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
    } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
    }

    throw new Error(errorMessage);
  }
},

};
