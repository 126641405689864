const Module = () => import("./Module.vue");
const ViewProducts = () => import("./views/ViewProducts.vue");
const ViewCategories = () => import("./views/ViewCategories.vue");
const AddProduct = () => import("./views/AddProduct.vue");
const EditProduct = () => import("./views/EditProduct.vue");
const ViewProduct = () => import("./views/ViewProduct.vue");

import Store from '../../store'; // Adjust the path as necessary to import your store

const moduleRoute = {
  path: "/inventories",
  component: Module,
  children: [
    {
      path: "", // This can act as a redirect or a default view if no specific path is matched
      name: "default",
      beforeEnter: (to, from, next) => {
        next({ name: "products" }); // Redirect to products as a default route
      }
    },
    {
      path: "categories",
      name: "categories",
      component: ViewCategories,
      meta: { },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        if (userType === 'admin' || permissions.includes('nav_inventories')) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: ":vendor_id",
      name: "products",
      component: ViewProducts,
      meta: { },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        if (userType === 'admin' || permissions.includes('nav_inventories')) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: "add",
      name: "add_products",
      component: AddProduct,
      meta: { },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        if (userType === 'admin' || permissions.includes('nav_inventories_add')) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: "edit/:id",
      name: "edit_products",
      component: EditProduct,
      meta: { },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        if (userType === 'admin' || permissions.includes('nav_inventories_add')) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: "view/:id",
      name: "view_product",
      component: ViewProduct,
      meta: { },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        if (userType === 'admin' || permissions.includes('nav_inventories')) {
          next();
        } else {
          next('/');
        }
      }
    },
  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
