import axios from 'axios';
import moment from 'moment';
import { encryptData, decryptData } from '../../../utils/encryption';
import io from 'socket.io-client';
import messaging from '@/firebaseInit'; // Import the messaging instance
import { getToken } from "firebase/messaging";

const SOCKET_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_REALTIME_URL// Replace this with your Node.js API base URL

const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_MISC_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_MISC_URL : process.env.VUE_APP_LOCAL_MISC_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL : process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL


export default {
  //EMPLOYEES
  async getEmployees({ commit }, { page = null, pageSize = null } = {}) {
    try {
      const params = {};
      if (page !== null && pageSize !== null) {
        params.page = page;
        params.pageSize = pageSize;
      }
      const response = await axios.get(`${API_SERVICE_URL}/employees`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employees = response.data.employees;
      if (employees) {
        commit('setEmployees', employees);
        return employees
      } else {
        throw new Error('Cannot Retreive Employees'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async getManagers({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/managers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const managers = response.data.managers;
      if (managers) {
        commit('setManagers', managers);
      } else {
        throw new Error('Cannot Retreive Managers'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },
  async getEmployeeRequests({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/requests/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_requests = response.data.employee_requests;
      if (employee_requests) {
        commit('setEmployeeRequests', employee_requests);
      } else {
        throw new Error('Cannot Retreive Emp Requests'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },
  async getStaffViewById({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/staff_view/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const staff_view = response.data.staff_view;
      if (staff_view) {
        return staff_view
      } else {
        throw new Error('Cannot Retreive Emp Requests'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },
  async getLeaves({ commit, dispatch }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/leaves`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.employee_leaves
      } else {
        throw new Error('Cannot Get Employee Leaves');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeLeave({ commit, dispatch }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/leaves/employee/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setEmployeeLeaves', response.data.employee_leaves);
      } else {
        throw new Error('Cannot Get Auth Employee Leave');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeInventory({ commit, dispatch }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/inventories/employee_inventories/employee/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setEmployeeInventories', response.data.employee_inventories);
      } else {
        throw new Error('Cannot Get Auth Employee Inventories');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeRequestByType({ commit }, type) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/requestbytype/${type}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_requests = response.data.employee_requests;
      if (employee_requests) {
        if(type === 'leave'){
        commit('setEmployeeLeaveRequests', employee_requests);
        }else if(type === 'transaction'){
          commit('setEmployeeTransactionRequests', employee_requests);
        }else if(type === 'transfer'){
          commit('setEmployeeTransferRequests', employee_requests);
        }else if(type === 'inventory'){
          commit('setEmployeeInventoryRequests', employee_requests);
        }
      } else {
        throw new Error('Cannot Retreive Emp Requests'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async searchEmployeesByQuery({ commit }, { query, designationIds = [], page = 1, pageSize = 10 }) {
    try {
      // Convert designationIds array into a comma-separated string if there are any IDs
      let designationIdsParam = '';
      if (Array.isArray(designationIds) && designationIds.length > 0) {
        designationIdsParam = designationIds.join(',');
      }
  
      const response = await axios.get(`${API_SERVICE_URL}/employees/getbyquery`, {
        params: {
          query,                // The search query (for name/contact/email)
          designationIds: designationIdsParam, // comma-separated string of IDs
          page,
          pageSize
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      commit('setEmployees', response.data.employees);
    } catch (error) {
      let errorMessage = 'Unknown error occurred';
  
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      } else {
        errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },  

  async addEmployee({ dispatch, commit }, employee) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/employees`, employee, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedEmployee', response.data.employee);
        await dispatch('getEmployees');
        return response.data.employee
      } else {
        throw new Error('Cannot Add Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateEmployee({ dispatch, commit }, employee) {

    try {
      const response = await axios.put(`${API_SERVICE_URL}/employees/${employee.uuid}`, employee, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedEmployee', response.data.employee);
        await dispatch('getEmployees');
      } else {
        throw new Error('Cannot Update Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeByAuth({ commit, dispatch }, user_id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/userId/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setEmployee', response.data.employee);
        commit('auth/setManpowerType', response.data.employee.ManpowerType ? response.data.employee.ManpowerType : null, { root: true });

        // Establish Socket.IO connection after successful employee fetch
        // const token = localStorage.getItem('token');
        dispatch('connectToSocket');
      } else {
        throw new Error('Cannot Get Auth Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeByUserId({ commit, dispatch }, user_id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/userId/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.employee
      } else {
        throw new Error('Cannot Get Employee By User ID');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployee({ commit }, user_id) {
    console.log('GET EMP BY ID - ', user_id)
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/userId/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedEmployee', response.data.employee);
      } else {
        throw new Error('Cannot Get Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeById({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedEmployee', response.data.employee);
      } else {
        throw new Error('Cannot Get Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async getEmployeeByScannedId({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setScannedEmployee', response.data.employee);
      } else {
        throw new Error('Cannot Get Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeePublic({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/uuid/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedEmployee', response.data.employee);
      } else {
        throw new Error('Cannot Get Employee');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  //EMP Document
  async addEmployeeDocument({ dispatch, commit }, employeeDocument) {
    if (employeeDocument['employeeId'] != null || employeeDocument['employeeId'] != undefined || employeeDocument['employeeId'] != '') {
      try {
        const response = await axios.post(`${API_SERVICE_URL}/employees/documents`, employeeDocument, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.status) {
          commit('setAddedDocument', response.data.employee_document);
          await dispatch('getEmployees');
        } else {
          throw new Error('Cannot ADD Employee Document');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    } else {
      throw new Error(`Error - Employee Id Not Found`);
    }

  },
  async updateEmployeeDocument({ dispatch, commit }, employeeDocument) {
    if (employeeDocument['employeeId'] != null || employeeDocument['employeeId'] != undefined || employeeDocument['employeeId'] != '') {
      let foundIdValue = null;
      for (const [key, value] of employeeDocument.entries()) {
        if (key === 'id') {
          foundIdValue = value;
          break; // Stop the loop once the 'id' value is found
        }
      }
      try {
        const response = await axios.put(`${API_SERVICE_URL}/employees/documents/${foundIdValue}`, employeeDocument, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.status) {
          commit('setAddedDocument', response.data.employee_document);
          await dispatch('getEmployees');
        } else {
          throw new Error('Cannot Update Employee Document');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    }
  },

  async deleteEmployeeDocument({ dispatch, commit }, id) {

    try {
      const response = await axios.delete(`${API_SERVICE_URL}/employees/documents/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        console.log('DELTED')
      } else {
        throw new Error('Cannot Delete Employee Document');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async addEmployeeDocuments({ dispatch, commit }, employeeDocuments) {

    try {
      let addedDocuments = []
      for (const document of employeeDocuments) {
        const formData = new FormData();
        // Assuming 'document' is a file object or similar

        for (let key in document) {
          if (key !== 'imageUrl' && key !== 'selected_file' && document[key] != null) {
            formData.append(key, document[key]);
          }
        }
        // Append 'selected_file' separately if needed
        if (document.selected_file) {
          formData.append('file', document.selected_file);
        }
        if (document.employeeId) {
          const response = await axios.post(`${API_SERVICE_URL}/employees/documents`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            }
          });

          if (response.data.status) {
            // commit('setAddedDocument', response.data.employee_document);
            addedDocuments.push(response.data.employee_document)
            console.log(' Doc Added- ', response.data.employee_document)
          } else {
            throw new Error('Cannot ADD Employee Document');
          }
        }


      }
      commit('setAddedDocuments', addedDocuments);
      await dispatch('getEmployees');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async updateEmployeeDocuments({ dispatch, commit }, employeeDocuments) {

    try {
      let addedDocuments = []
      for (const document of employeeDocuments) {
        const formData = new FormData();
        // Assuming 'document' is a file object or similar

        for (let key in document) {
          if (key !== 'imageUrl' && key !== 'selected_file' && document[key] != null) {
            formData.append(key, document[key]);
          }
        }
        // Append 'selected_file' separately if needed
        if (document.selected_file) {
          formData.append('file', document.selected_file);
        }
        if (document.employeeId) {
          const response = await axios.put(`${API_SERVICE_URL}/employees/documents/${document['id']}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            }
          });

          if (response.data.status) {
            // commit('setAddedDocument', response.data.employee_document);
            addedDocuments.push(response.data.employee_document)
            console.log(' Doc Updated- ', response.data.employee_document)
          } else {
            throw new Error('Cannot Update Employee Document');
          }
        }

      }
      commit('setAddedDocuments', addedDocuments);
      await dispatch('getEmployees');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },


  // EMployee Assignment

  //Employee Assignments
  async getEmployeeAssignments({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/assignments`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_assignments = response.data.employee_assignments;
      if (employee_assignments) {
        commit('setEmployeeAssignments', employee_assignments);
        return employee_assignments;
      } else {
        throw new Error('Cannot Retreive Assignments'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },
  async getActiveEmployeeAssignments({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/active_assignments`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_assignments = response.data.employee_assignments;
      if (employee_assignments) {
        commit('setEmployeeAssignments', employee_assignments);
        return employee_assignments
      } else {
        throw new Error('Cannot Retreive Assignments'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async addEmployeeAssignments({ dispatch, commit }, employeeAssignments) {

    try {
      let addedEmployeeAssignments = []
      for (const assignment of employeeAssignments) {

        if (assignment.employeeId && assignment.contractDetailId) {
          const response = await axios.post(`${API_SERVICE_URL}/employees/assignments`, assignment, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });

          if (response.data.status) {
            // commit('setAddedDocument', response.data.employee_document);
            addedEmployeeAssignments.push(response.data.employee_assignment)
            console.log(' Assignment Added- ', response.data.employee_assignment)
          } else {
            throw new Error('Cannot ADD Employee Assignment');
          }
        }


      }
      commit('setAddedEmployeeAssignments', addedEmployeeAssignments);
      await dispatch('getEmployeeAssignments');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateEmployeeAssignments({ dispatch, commit }, employeeAssignments) {

    try {
      let addedEmployeeAssignments = []
      for (const assignment of employeeAssignments) {

        if (assignment.id && assignment.employeeId && assignment.contractDetailId) {
          const response = await axios.put(`${API_SERVICE_URL}/employees/assignments/${assignment.id}`, assignment, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });

          if (response.data.status) {
            // commit('setAddedDocument', response.data.employee_document);
            addedEmployeeAssignments.push(response.data.employee_assignment)
            console.log(' Assignment Updated- ', response.data.employee_assignment)
          } else {
            throw new Error('Cannot Update Employee Assignment');
          }
        }


      }
      commit('setAddedEmployeeAssignments', addedEmployeeAssignments);
      await dispatch('getEmployeeAssignments');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async deleteEmployeeAssignments({ dispatch, commit }, employeeAssignments) {

    try {
      for (const assignment of employeeAssignments) {

        if (assignment.id) {
          const response = await axios.delete(`${API_SERVICE_URL}/employees/assignments/${assignment.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });

          if (response.data.status) {
            console.log(' Assignment Deleted- ', assignment.id)
          } else {
            throw new Error('Cannot Delete Employee Assignment');
          }
        }


      }
      await dispatch('getEmployeeAssignments');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async getCurrentTimeEmployeeAssignmentByUUID({ dispatch, commit }, currentData) {

    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/assignments/now/${currentData.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          currentDate: currentData.currentDate
        }
      });
      if (response.data.status) {
        if (response.data.employee_assignment && response.data.employee_assignment.id) {
          commit('setNowEmployeeAssignment', response.data.employee_assignment)
        } else {
          commit('setNowEmployeeAssignment', null)
        }
      } else {
        throw new Error('Cannot Get Employee Assignment For Now Time');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async getCurrentTimeEmployeeAttendanceByUUID({ dispatch, commit }, currentData) {

    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/attendances_view/${currentData.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          currentDate: currentData.currentDate
        }
      });
      if (response.data.status) {
        return response.data.employee_other_attendances
      } else {
        throw new Error('Cannot Get Employee Attendance For current Time');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },



  //Other Attendance
  async getEmployeeOtherAttendances({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/other_attendances`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_other_attendances = response.data.employee_other_attendances;
      if (employee_other_attendances) {
        commit('setEmployeeOtherAttendances', employee_other_attendances);
      } else {
        throw new Error('Cannot Retreive Other Attendances'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },
  async getEmployeeOtherAttendancesToday({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/other_attendances/today`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_other_attendances = response.data.employee_other_attendance;
      if (employee_other_attendances) {
        commit('setEmployeeOtherAttendancesToday', employee_other_attendances);
      } else {
        throw new Error('Cannot Retreive Other Attendances'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },
  async addEmployeeOtherAttendance({ dispatch, commit }, employeeOtherAttendance) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/employees/other_attendances`, employeeOtherAttendance, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status) {
        commit('setAddedEmployeeOtherAttendance', response.data.employee_other_attendance);
        await dispatch('getEmployeeOtherAttendances');
      } else {
        throw new Error('Cannot ADD Employee Other Attendance');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateEmployeeOtherAttendance({ dispatch, commit }, employeeOtherAttendance) {
    if (employeeOtherAttendance.id) {
      try {
        const response = await axios.put(`${API_SERVICE_URL}/employees/other_attendances/${employeeOtherAttendance.id}`, employeeOtherAttendance, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.status) {
          commit('setAddedEmployeeOtherAttendance', response.data.employee_other_attendance);
          await dispatch('getEmployeeOtherAttendances');
        } else {
          throw new Error('Cannot Update Employee Other Attendance');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    } else {
      throw new Error(`Error - Id not found`);
    }


  },


  //TRANSACTION
  async updateTransaction({ dispatch, commit }, transaction) {
    if (transaction.id) {
      try {
        const response = await axios.put(`${API_SERVICE_URL}/business/transactions/${transaction.id}`, transaction, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.status) {
          return response.data.transaction
        } else {
          throw new Error('Cannot Update Transaction');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    } else {
      throw new Error(`Error - Id not found`);
    }


  },
  //INCOMES

  async getIncomes({ commit }, employeeId) {
    console.log('GET Incomes BY ID - ', employeeId)
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/incomes/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        // commit('setAddedEmployee', response.data.employee);
        commit('setIncomes', response.data.incomes);
      } else {
        throw new Error('Cannot Get Incomes');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  // EMP ASSIGNMENTS VIEW

  async getEmployeeAssignmentsView({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/assignments_view`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const assignments = response.data.employee_assignments_view
      if (assignments) {
        // return response.data.employee_assignments_view; // Returning the result directly
        return assignments; // Returning the result directly
      } else {
        return []
      }

    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async getEmployeeAssignmentsViewByEmpId({ commit }, employeeId) {
    console.log('empid', employeeId)
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/assignments_view/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const assignments = response.data.employee_assignments_view
      if (assignments) {
        // return response.data.employee_assignments_view; // Returning the result directly
        return assignments; // Returning the result directly
      } else {
        return []
      }

    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getEmployeeAttendanceView({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/attendances_view`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_attendances = response.data.employee_attendances
      if (employee_attendances) {
        // return response.data.employee_assignments_view; // Returning the result directly
        return employee_attendances; // Returning the result directly
      } else {
        return []
      }

    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },


  //REALTIME AND FCM

  async addUserToken({ dispatch, commit }, token) {
    let tokenData = {
      fcmToken: token
    }
    try {
      const response = await axios.post(`${API_BASE_URL}/api/fcmtoken`, tokenData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        console.log('Token Updated!')
      } else {
        throw new Error('Cannot Add Token');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async requestNotificationPermission({ commit, dispatch }) {
    // Check if Notification API is available
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications.");
      return;
    }

    // Check if permission is already granted
    if (Notification.permission === "granted") {
      dispatch('getFcmToken');
      return;
    }

    // Request permission from the user
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        dispatch('getFcmToken');
      } else {
        console.log("Notification permission denied.");
      }
    });
  },

  getFcmToken({ commit, dispatch }) {
    getToken(messaging, { vapidKey: "BIwLtcwbvBdzzYKu8WIbhOQIzIFW_qOxv67NQeAif6VNqxJVKitkIs7FZ8kAK8nKOc7GC-9qqi7aYxUeSQK6cCU" })
      .then((token) => {
        if (token) {
          commit('setFcmToken', token);
          dispatch('addUserToken', token)
          console.log('FCM Token:', token);
          // Optionally, send this token to your server
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });
  },

  connectToSocket({ state, commit, dispatch }) {
    const token = localStorage.getItem('token');
    let options = {}
    if(process.env.VUE_APP_ENV === 'production'){
      options = {
        path: "/realtime/socket.io/",
        query: { token },
        transports: ['websocket']
      }
    }else{
      options = {
        query: { token },
        transports: ['websocket']
      }
    }
    const socket = io(SOCKET_URL, options);

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
      commit('setIsOnline', true);
    });

    socket.on('userConnected', (userId) => {
      // Fetch and add this user to the online list
      console.log('USer Connected - ', userId)
      dispatch('fetchAndAddOnlineUser', userId);
    });

    socket.on('userDisconnected', (userId) => {
      // Remove this user from the online list
      commit('removeOnlineUser', userId);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server');
      commit('setIsOnline', false);
    });

    socket.on('connect_error', (err) => {
      console.error('Connection Error:', err);
      commit('setIsOnline', false);
      // Handle connection errors
    });

    // Add more event listeners as required for your application
  },
  async fetchAndAddOnlineUser({ commit, state }, userId) {
    try {
  
      // Check if the user ID is valid
      if (!userId) {
        console.log("Invalid user ID.");
        return;
      }
  
      // Check if the employee is already in the online list
      if (state.onlineEmployees.some(emp => emp.user_id == userId)) {
        return;
      }
  
      // Check if a fetch operation for this user is already in progress
      if (state.pendingUserFetches.includes(userId)) {
        return;
      }
  
      // Mark this user ID as a fetch in progress
      commit('markUserFetchInProgress', userId);
  
      const response = await axios.get(`${API_SERVICE_URL}/employees/userId/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
  
      // Remove the user ID from fetches in progress
      commit('unmarkUserFetchInProgress', userId);
  
      if (response.data && response.data.status && response.data.employee) {
        commit('addOnlineUser', response.data.employee);
        // const emp = response.data.employee;
        // if(emp.managerId === state.employee.uuid){
        //   console.log(`${emp.name} is online`);
        // }
      }
    } catch (error) {
      console.error(`Error fetching employee data for user ${userId}:`, error);
    }
  },

  //LEAVEs
  async addLeave({ dispatch, commit }, leave) {
    try{
      const response = await axios.post(`${API_SERVICE_URL}/employees/leaves`, leave, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status) {
        return response.data.leave
      } else {
        throw new Error('Cannot Add Leave');
      }
    }
    catch(error){
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }
    }
  },
  async updateLeaves({ dispatch, commit }, leave) {
    if (leave.id) {
      try {
        const response = await axios.put(`${API_SERVICE_URL}/employees/leaves/${leave.id}`, leave, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.status) {
          return response.data.leave
        } else {
          throw new Error('Cannot Update Leave');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    } else {
      throw new Error(`Error - Id not found`);
    }

  },

  //REmove Assignment By ID
  async deleteAssignment({ commit, dispatch }, id) {
    try {
      const response = await axios.delete(`${API_SERVICE_URL}/employees/assignments/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.status
      } else {
        throw new Error('Cannot Get Auth Employee Leave');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

};
